<template>
  <div>
    <Header></Header>

    <Hero v-bind:images="[images['hero-menu']]" v-bind:texts="[]" v-bind:height="300"></Hero>

    <section class="py-8">
      <div class="container mx-auto text-center">
        <div class="flex flex-wrap mb-4">
          <div class="md:w-1/2 px-8 pt-12">
            <h2 class="pb-8">Healthy from a-z</h2>
            <p>
              Our signature crêpe batter recipe, made of egg (except in India), water and flour is
              tasty and light, ensuring we provide the best quality to you from
              start to finish. Cooked on a hotplate, there is no need for butter
              or oil in the cooking process.
            </p>
          </div>
          <div class="md:w-1/2 px-8 pt-12">
            <h2 class="pb-8">Eat fresh</h2>
            <p>
              Our selection of ingredients ranges from the freshest fruits and
              vegetables to quality meat and dairy. All ingredients are prepared
              daily, from slicing & dicing to cooking, we want our meals to be
              as nutritious, filling and tasty as possible.
            </p>
          </div>
          <div class="md:w-1/2 px-8 pt-12">
            <h2 class="pb-8">Nothing to hide</h2>
            <p>
              The interactive crêpe cooking process means we have nothing to
              hide; what you see is what you get. All ingredients are kept cold
              and fresh, in full view of you. Watch as your crêpe is given a
              twist, turn and flip by one of our friendly staff.
            </p>
          </div>
          <div class="md:w-1/2 px-8 pt-12">
            <h2 class="pb-8">Bespoke dining</h2>
            <p>
              Want extra chicken, less cheese and touch more mushroom?.. let us
              know! We’re here to make this the best dining experience possible.
              If our extensive menu doesn’t tempt your tastebuds, we’ll be happy
              to tweak it just for you.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="xl:max-w-6xl mx-auto xl:mb-24">
      <section
        class="mt-16 h-64 bg-center bg-no-repeat bg-cover"
        style="background-image: url('/img/menu-1.jpg')"
      >
        <span
          @click="showModal = true, type = 'crepes'"
          class="h-full flex flex-col items-center bg-tcc-b25 hover:bg-tcc-b50 justify-center uppercase text-2xl text-white cursor-pointer"
        >
          <span>The Crepe</span>
          <span class="text-6xl leading-none">+</span>
        </span>
      </section>

      <section
        class="h-64 bg-center bg-no-repeat bg-cover"
        style="background-image: url('/img/menu-2.jpg')"
      >
        <span
          @click="showModal = true, type = 'delights'"
          class="h-full flex flex-col items-center bg-tcc-b25 hover:bg-tcc-b50 justify-center uppercase text-2xl text-white cursor-pointer"
        >
          <span>Food Delights</span>
          <span class="text-6xl leading-none">+</span>
        </span>
      </section>

      <section
        class="h-64 bg-center bg-no-repeat bg-cover"
        style="background-image: url('/img/menu-3.jpg')"
      >
        <span
          @click="showModal = true, type = 'coffee'"
          class="h-full flex flex-col items-center bg-tcc-b25 hover:bg-tcc-b50 justify-center uppercase text-2xl text-white cursor-pointer"
        >
          <span>Signature Coffee</span>
          <span class="text-6xl leading-none">+</span>
        </span>
      </section>

      <section
        class="h-64 bg-center bg-no-repeat bg-cover"
        style="background-image: url('/img/menu-4.jpg')"
      >
        <span
          @click="showModal = true, type = 'juices'"
          class="h-full flex flex-col items-center bg-tcc-b25 hover:bg-tcc-b50 justify-center uppercase text-2xl text-white cursor-pointer"
        >
          <span>Specialty Drinks</span>
          <span class="text-6xl leading-none">+</span>
        </span>
      </section>
    </div>

    <Footer></Footer>

    <MenuModal v-bind:show="showModal" v-bind:type="type" @close="showModal = false"></MenuModal>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import MenuModal from "../components/MenuModal";

import images from "../img/*.jpg";

export default {
  props: [],
  components: {
    Header,
    Footer,
    Hero,
    MenuModal
  },
  data: () => ({ images, showModal: false, type: "crepes" })
};
</script>