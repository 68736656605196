<template>
  <transition name="modal">
    <div
      v-if="show"
      class="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
    >
      <div
        v-for="(content, index) in menuData.content"
        v-bind:key="index"
        class="modal-overlay absolute w-full h-full bg-black bg-tcc-50 bg-center bg-no-repeat bg-contain"
        v-bind:style="{ backgroundImage: `url('${images[content.image]}' )` }"
        v-show="currentIndex === index"
      ></div>

      <div class="modal-container fixed w-full h-full z-50 overflow-y-auto">
        <div
          @click="$emit('close')"
          class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-16 mr-4 text-white text-sm z-50 p-3 bg-tcc-b50"
        >
          <svg
            class="fill-current text-white"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
        </div>

        <div class="inline-block p-6 bg-tcc-b50 m-16 text-white max-w-xl">
          <!--Body-->
          <h1 class="uppercase text-4xl font-bold py-4">{{ menuData.content[currentIndex].title }}</h1>
          <p>{{ menuData.content[currentIndex].text }}</p>
        </div>

        <div class="absolute bottom-0 right-0 p-6 bg-tcc-b50 cursor-pointer" v-on:click="next">
          <span class="text-white uppercase text-xl px-6">{{ menuData.more }}</span>
          <button class="px-4 bg-tcc-grey p-3 rounded-full border-2 border-white text-white mr-2">→</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { data } from "./MenuModal.data";
import images from "../img/menu/*.jpg";

export default {
  props: ["show", "type"],
  data: () => ({ images, currentIndex: 0 }),
  watch: {
    type: function(oldVal, newVal) {
      this.currentIndex = 0;
    }
  },
  computed: {
    menuData: function() {
      return data[this.type];
    }
  },
  methods: {
    next: function(event) {
      this.currentIndex =
        (this.currentIndex + 1) % this.menuData.content.length;
    }
  }
};
</script>

<style scoped>
.modal {
  transition: transform 0.25s ease;
  z-index: 20;
}

body.modal-active {
  overflow-x: hidden;
}

.modal-enter {
  transform: translate(100%, 0);
}

.modal-leave-active {
  transform: translate(100%, 0);
}
</style>