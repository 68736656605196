<template>
  <nav
    class="relative md:sticky md:top-0 bg-tcc-yellow bg-left-top bg-no-repeat z-20"
    style="background-image: url('/img/bg-header.svg'); background-size: auto 69px;"
  >
    <div class="absolute top-0 left-0 m-2" style="z-index: 100">
      <a href="/">
        <img class="header-logo" src="/img/tcc-logo.svg" alt="The Crêpe Café Logo" />
      </a>
    </div>
    <div class="flex items-center justify-end flex-wrap p-6">
      <div class="block md:hidden">
        <button
          class="flex items-center px-3 py-2 border rounded text-black border-teal-400 hover:text-white hover:border-white"
        >
          <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
      </div>
      <div class="w-full block md:flex md:items-center md:w-auto">
        <div class="text-sm text-center md:flex-grow">
          <a
            href="/#about"
            class="block mt-4 md:inline-block md:mt-0 text-black hover:text-white md:mr-8"
          >About</a>
          <a
            href="/menu"
            class="block mt-4 md:inline-block md:mt-0 text-black hover:text-white md:mr-8"
          >Products</a>
          <a
            href="/gallery"
            class="block mt-4 md:inline-block md:mt-0 text-black hover:text-white md:mr-8"
          >Gallery</a>
          <a
            href="/franchising"
            class="block mt-4 md:inline-block md:mt-0 text-black hover:text-white md:mr-8"
          >Franchising</a>
          <a
            href="/#contact"
            class="block mt-4 md:inline-block md:mt-0 text-black hover:text-white"
          >Contact</a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: []
};
</script>
