<template>
  <div id="app">
    <component v-bind:is="ViewComponent"></component>
  </div>
</template>

<script>
import Index from "./pages/Index";
import Menu from "./pages/Menu";
import Gallery from "./pages/Photos";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyStatement from "./pages/PrivacyStatement";
import Franchising from "./pages/Franchising";

const NotFound = { template: "<p>Page not found</p>" };

const routes = {
  "/": Index,
  "/menu": Menu,
  "/gallery": Gallery,
  "/terms-of-use": TermsOfUse,
  "/privacy-statement": PrivacyStatement,
  "/franchising": Franchising
};

export default {
  name: "app",
  data: () => ({
    currentRoute: window.location.pathname
  }),
  computed: {
    ViewComponent() {
      return routes[this.currentRoute] || NotFound;
    }
  },
  components: {
    Index,
    Menu
  }
};
</script>