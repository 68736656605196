<template>
  <div>
    <Header></Header>

    <Hero
      v-bind:images="[heroImages['hero-ps']]"
      v-bind:texts="[]"
      v-bind:height="300"
    ></Hero>

    <div class="container mx-auto">
      <h2>Our most recent shops</h2>
    </div>

    <section class="container mx-auto flex flex-wrap my-12">
      <div
        class="w-64 h-64 bg-cover cursor-pointer opacity-90 hover:opacity-100 relative"
        v-for="(image, imageIndex) in recentImages"
        v-bind:key="image.id"
        @click="index = imageIndex"
        :style="{ backgroundImage: `url('${galleryImages[image.name]}')` }"
      >
        <p class="w-full text-white bg-tcc-b75 p-1 absolute bottom-0">
          {{ image.title }}
        </p>
      </div>

      <div
        class="fixed top-0 left-0 bottom-0 right-0 py-24 bg-tcc-b50 flex items-center justify-center"
        v-if="recentIndex != null"
        @click="recentIndex = null"
        style="z-index: 10"
      >
        <span
          class="text-white text-6xl p-8 cursor-pointer"
          @click.stop="recentIndex = mod(recentIndex - 1, recentImages.length)"
          >‹</span
        >
        <div class="flex flex-col items-center h-full">
          <img
            class="inline-block max-h-full"
            :src="galleryImages[recentImages[recentIndex].name]"
            :alt="recentImages[recentIndex].title"
          />
          <p class="w-full text-black font-semibold bg-tcc-yellow p-3">
            {{ recentImages[recentIndex].description }}
          </p>
        </div>
        <span
          class="text-white text-6xl p-8 cursor-pointer"
          @click.stop="recentIndex = mod(recentIndex + 1, recentImages.length)"
          >›</span
        >
      </div>
    </section>

    <div class="container mx-auto">
      <h2>Some examples of our outlets</h2>
    </div>

    <section class="container mx-auto flex flex-wrap my-12">
      <div
        class="w-64 h-64 bg-cover cursor-pointer opacity-90 hover:opacity-100 relative"
        v-for="(image, imageIndex) in images"
        v-bind:key="image.id"
        @click="index = imageIndex"
        :style="{ backgroundImage: `url('${galleryImages[image.name]}')` }"
      >
        <p class="w-full text-white bg-tcc-b75 p-1 absolute bottom-0">
          {{ image.title }}
        </p>
      </div>

      <div
        class="fixed top-0 left-0 bottom-0 right-0 py-24 bg-tcc-b50 flex items-center justify-center"
        v-if="index != null"
        @click="index = null"
        style="z-index: 10"
      >
        <span
          class="text-white text-6xl p-8 cursor-pointer"
          @click.stop="index = mod(index - 1, images.length)"
          >‹</span
        >
        <div class="flex flex-col items-center h-full">
          <img
            class="inline-block max-h-full"
            :src="galleryImages[images[index].name]"
            :alt="images[index].title"
          />
          <p class="w-full text-black font-semibold bg-tcc-yellow p-3">
            {{ images[index].description }}
          </p>
        </div>
        <span
          class="text-white text-6xl p-8 cursor-pointer"
          @click.stop="index = mod(index + 1, images.length)"
          >›</span
        >
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

import heroImages from "../img/*.jpg";
import galleryImages from "../img/gallery/*.jpg";

export default {
  components: {
    Header,
    Footer,
    Hero,
  },
  data() {
    return {
      heroImages,
      galleryImages,
      recentIndex: null,
      index: null,
      recentImages: [
        {
          title: "Tabuk 2019",
          description: "Tabuk 2019",
          name: "Tabuk - 2019",
        },
        {
          title: "Taif 2019",
          description: "Taif 2019",
          name: "Taif - 2019",
        },
        {
          title: "Ranchi 2020",
          description: "Ranchi 2020",
          name: "Ranchi - 2020",
        },
        {
          title: "Hyderabad 2020",
          description: "Hyderabad 2020",
          name: "Hyderabad - 2020",
        },
        {
          title: "Khamis 2020",
          description: "Khamis 2020",
          name: "Khamis 1 - 2020",
        },
        {
          title: "Khamis 2020",
          description: "Khamis 2020",
          name: "Khamis 2 - 2020",
        },
        {
          title: "Bengaloru 2020",
          description: "Bengaloru 2020",
          name: "Bengaloru 2020 - kiosk",
        },
        {
          title: "Cairo 2020",
          description: "Cairo 2020",
          name: "Cairo 2020 - shop 9",
        },
        {
          title: "Tabuk 2021 - 1",
          description: "Tabuk 2021 - 1",
          name: "Tabuk 2021 - 1",
        },
        {
          title: "Tabuk 2021 - 2",
          description: "Tabuk 2021 - 2",
          name: "Tabuk 2021 - 2",
        },
        {
          title: "Tabuk 2021 - 3",
          description: "Tabuk 2021 - 3",
          name: "Tabuk 2021 - 3",
        },
        {
          title: "Tabuk 2021 - 4",
          description: "Tabuk 2021 - 4",
          name: "Tabuk 2021 - 4",
        },
      ].reverse(),
      images: [
        {
          title: "Australia Gold Coast 2003",
          description: "Australia Gold Coast 2003",
          name: "Gold Coast 2003 - kiosk 1",
        },
        {
          title: "Australia Gold Coast 2003",
          description: "Australia Gold Coast 2003",
          name: "Gold Coast 2003 - kiosk 2",
        },
        {
          title: "Australia Gold Coast 2005",
          description: "Australia Gold Coast 2005",
          name: "Gold Coast 2005 - shop 1",
        },
        {
          title: "Australia Gold Coast 2006",
          description: "Australia Gold Coast 2006",
          name: "Gold Coast 2006 - kiosk 3",
        },
        {
          title: "Australia Gold Coast 2006",
          description: "Australia Gold Coast 2006",
          name: "Gold Coast 2006 - kiosk 4",
        },
        {
          title: "Australia Gold Coast 2006",
          description: "Australia Gold Coast 2006",
          name: "Gold Coast 2006 - kiosk 5",
        },
        {
          title: "Australia Melbourne 2005",
          description: "Australia Melbourne 2005",
          name: "Melbourne 2005 - kiosk 6",
        },
        {
          title: "Jeddah 2009",
          description: "Jeddah 2009",
          name: "Jeddah 2009 - shop 1",
        },
        {
          title: "Jeddah 2009",
          description: "Jeddah 2009",
          name: "Jeddah 2009 - shop 2",
        },
        {
          title: "Jeddah 2009",
          description: "Jeddah 2010",
          name: "Jeddah 2010 - shop 4",
        },
        {
          title: "Jeddah 2009",
          description: "Jeddah 2010",
          name: "Jeddah 2010 - shop 8",
        },
        {
          title: "Bahrain 2009",
          description: "Bahrain 2010",
          name: "Bahrain 2010 - shop 7",
        },
        {
          title: "Riyadh 2009",
          description: "Riyadh 2009",
          name: "Riyadh 2009 - shop 3",
        },
        {
          title: "Abu Dhabi 2011",
          description: "Abu Dhabi 2011",
          name: "Abu Dhabi 2011 - shop 6",
        },
      ].reverse(),
    };
  },
  methods: {
    mod(n, m) {
      return ((n % m) + m) % m;
    },
  },
};
</script>
