<template>
  <footer class="bg-tcc-grey border-t-8 border-tcc-yellow py-8 text-white">
    <div class="sm:flex container sm:mx-auto mx-6">
      <div class="mr-24 mb-6 lg:mr-32">
        <img src="/img/tcc-logo.svg" alt="The Crêpe Café logo" />
        <span class="opacity-75 text-3xl">The Crêpe Café</span>
        <p class="opacity-50 text-xs">© Copyright {{ new Date().getFullYear() }} BFC Retail Group</p>
        <p class="opacity-50 text-xs">All Rights Reserved</p>
      </div>
      <div class="mb-6 sm:mr-24 lg:mr-32">
        <ul class="leading-loose">
          <li>
            <a class="block hover:text-tcc-yellow" href="/#about">About</a>
          </li>
          <li>
            <a class="block hover:text-tcc-yellow" href="/menu">Products</a>
          </li>
          <li>
            <a class="block hover:text-tcc-yellow" href="/gallery">Gallery</a>
          </li>
          <li>
            <a class="block hover:text-tcc-yellow" href="/franchising">Franchising</a>
          </li>
          <li>
            <a class="block hover:text-tcc-yellow" href="/#contact">Contact</a>
          </li>
        </ul>
      </div>
      <div class="flex-grow mb-6 sm:mr-12 opacity-75">
        <ul class="leading-loose">
          <li>
            <a class="block hover:text-tcc-yellow" href="/privacy-statement">Privacy statement</a>
          </li>
          <li>
            <a class="block hover:text-tcc-yellow" href="/terms-of-use">Terms of use</a>
          </li>
        </ul>
      </div>
      <div class="flex">
        <a target="_blank" href="https://www.facebook.com/thecrepecafe.corporate/">
          <img class="w-8 h-8 mr-4 rounded bg-gray-300 p-1" src="/img/fb-logo.png" alt="Facebook" />
        </a>
        <a target="_blank" href="https://www.instagram.com/tccbfc/">
          <img class="w-8 h-8 mr-4 rounded bg-gray-300 p-1" src="/img/insta-logo.png" alt="Flickr" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: []
};
</script>