<template>
  <div>
    <Header></Header>

    <header class="md:sticky md:top-header bg-tcc-grey text-white z-10 p-6">
      <ul
        class="text-sm text-center md:flex-grow md:flex md:items-center md:w-auto font-semibold md:justify-center"
      >
        <li class="mt-4 md:mt-0 px-6 hover:border-b-2 hover:border-tcc-orange">
          <a href="#product">A product plus</a>
        </li>
        <li class="mt-4 md:mt-0 px-6 hover:border-b-2 hover:border-tcc-orange">
          <a href="#business">A Business plus</a>
        </li>
        <li class="mt-4 md:mt-0 px-6 hover:border-b-2 hover:border-tcc-orange">
          <a href="#faq">FAQ</a>
        </li>
      </ul>
    </header>

    <Hero v-bind:images="[images['hero-menu']]" v-bind:texts="[]" v-bind:height="300"></Hero>

    <section class="text-center p-24">
      <div class="container mx-auto">
        <h2>FRANCHISING WITH BFC RETAIL GROUP</h2>
        <p class="pt-8 leading-relaxed">
          Franchising with BFC Retail Group has an abundance of advantages from both a concept perspective, with a unique product offering, and a business perspective, with minimal food wastage and little to zero competition. To understand your advantages in depth scroll to
          <a
            class="hover:text-tcc-yellow underline"
            href="#productPlus"
          >A Product Plus</a>,
          <a class="hover:text-tcc-yellow underline" href="#businessPlus">A Business Plus</a> or explore our FAQ.
        </p>
        <p class="py-4">
          The trademark orange of Flying Pan and yellow of The Crêpe Café, is a geographical display of our international presence around the world.
          The cultural diversity of our team and respect for multiculturalism is reflected in our products' offering. Extensive experience with a range of nationalities and cultural needs has given BFC Retail Group the knowledge and understanding they need to make product adaptation easy and successful. From South Korea to the United States and Australia to Brazil, Flying Pan and The Crêpe Café provide enjoyable fresh dining experiences.
        </p>
        <p class="text-center mt-8">
          <a
            class="bg-yellow-500 hover:bg-yellow-700 text-black text-2xl font-bold rounded focus:outline-none focus:shadow-outline inline-block mt-6 px-16 py-8"
            href="/static/TCC_Corporate Brochure_2019.pdf"
            target="_blank"
          >Download our brochure</a>
        </p>
      </div>
    </section>

    <section
      class="py-8 h-56 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/hero-2.jpg')"
      id="product"
    ></section>

    <section class="p-24">
      <div class="container mx-auto">
        <h3>A product plus</h3>
        <h4>An innovative concept</h4>
        <p>The concept is based on an authentic food tradition, which dates back centuries and has not truly been marketed yet to its full extent. There are not many new innovative food concepts that can be introduced in the market with global appeal.</p>
        <h4>Familiar flavors with international inspiration</h4>
        <p>“crêpes” in the broadest sense are common in many regions worldwide, from the French-style crêpe to the American pancake or the Spanish tortilla and the Indian dosa… The “beauty” of our crêpes lies in their adaptability to any taste bud, country or culture. People can indulge in crêpes in many ways: hot or cold, sweet or savory, plain or with filling. The combinations are nearly endless and are only limited by our imagination and personal preferences. Local product adaptation (either at a regional or cultural level) is very easy and enables us, theoretically, to successfully enter any market worldwide.</p>

        <h4>A visual show</h4>
        <p>Making crêpes is both entertaining for the crêpe-maker and the customer who can watch the crêpe being created in front of them. The aromas of crêpe, waffle or pancake cooking is tempting to any customers walking past. This provides a strong point of differentiation compared to other food brands. This theatrical effect, in a clean and pristine environment, is a great marketing tool, which grabs people’s attention and gives them the opportunity to (re)discover our products and enjoy either now or at a later stage.</p>

        <h4>Fresh & healthful</h4>
        <p>As crêpes are prepared in front of customers, they are assured that the products used are fresh and appetizing. People feel more comfortable when they are able to watch the preparation and cooking process and realize we do not hide anything. Also, they can notice how healthful our cooking process is, we do not use any oil or butter to cook crêpes, and the how fresh and delicious our ingredients are.</p>

        <h4>All day trade</h4>
        <p>Crêpes can be filled with nearly everything, from savory to sweet ingredients, depending on what people feel like at a given time. This means that due to the nature and appeal of our products, customers can eat crêpes for any meal of the day (or as a snack), and it gives our franchisees the opportunity to maximize their daily sales. Our core star product is complemented admirably by our wide array of gourmet espresso drinks and coffees.</p>

        <h4>Diverse café fare (region dependent)</h4>
        <p>Our core products are crêpes and coffees however we also have a wide selection of additional café fare items: omelettes, soups, salads, paninis, waffles, pancakes, ice-creams, hot or iced drinks, smoothies and soft drinks. These are usually “side products” which are served as sides but they can also be ordered independently. The majority of these side products are complementary to our core products and do not burden unnecessarily our operations whilst still presenting our customers with a wide product choice. They enable us to capture a greater clientele at different times of the day. All of these products directly contribute to increasing the value of the average basket/docket and significantly increase the overall turnover with little involvement or cost of the operator.</p>
        <p class="text-center mt-8">
          <a
            class="bg-yellow-500 hover:bg-yellow-700 text-black text-2xl font-bold rounded focus:outline-none focus:shadow-outline inline-block mt-6 px-16 py-8"
            href="/static/TCC_Corporate Brochure_2019.pdf"
            target="_blank"
          >Download our brochure</a>
        </p>
      </div>
    </section>

    <section
      class="py-8 h-56 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/hero-1.jpg')"
      id="business"
    ></section>

    <section class="p-24">
      <div class="container mx-auto">
        <h3>A business plus</h3>
        <h4>Competitive pricing with low food cost and low food wastage</h4>
        <p>Ingredients are prepared daily as needed, and our products are cooked to order, there is very little wastage and food costs are extremely low. We have specifically researched ideal quantities to simultaneously respond adequately to our customers’ needs and minimize the food cost for the operator. If our guidelines are respected, it is a win-win situation for everyone. In the end, we strive to have as low a food cost as possible since we realize its critical impact on profit margins and franchisees’ satisfaction. This enables us to maintain very competitive pricing and to position ourselves within the fast casual segment.</p>
        <h4>Easy training and operation</h4>
        <p>Making crêpes is not a difficult process once the person has been properly trained. Everyone can make crêpes but the training makes a crucial difference in terms of quality of finished products, efficiency and good running of operations. Finally, the best part about making crêpes is that it is fun!</p>
        <h4>Limited daily preparation time</h4>
        <p>Compared to others food operations, there is minimal preparation requirements, and cooking time is a maximum 4 minutes for the crêpe, waffle or pancake. With the flexibility of offering half service or full service operation a well-trained standard operation on a busy period can run smoothly with limited staff. Generally, customers are required to order and pay at the counter before seating.</p>
        <p>Each unit can be managed on its own independently without a central Kitchen</p>

        <h4>A versatile concept welcomed by landlords as our concept’s uniqueness adds value to an area’s tenant mix</h4>
        <p>Our concept is exportable and adaptable in a wide range of locations and situations. This is enhanced by the fact that, in most territories, we will not need expensive exhaust systems to operate our hot plates.</p>
        <h4>Limited to no direct competition</h4>
        <p>There are very few recognized names in the crêpe industry. The majority of the competition does not put crêpes at the centre of their concepts. Currently, there is no genuine French inspired crêpes company with a franchisable system in place and a worldwide reach.</p>
        <h4>Easily adaptable to small and large footprints (Street Café, kiosk, and Mobile)</h4>
        <p>We promote two main business positions:</p>
        <h4>A Full Café concept, 1,000 to 2,500 sqf (90 to 230 sqm), usually located in streets or corner positions in malls or inside / outside location and</h4>
        <p>A Large Kiosk concept, around 400 sqf (37 sqm), if possible with a private area of 35 seats or more, usually located in shopping mall intersections.</p>

        <p>In some circumstances, these two main types can be complemented by smaller kiosks or mobile units (for catering purposes) which are always linked to a bigger outlet. A van / truck based mobile concept is also in development.</p>
        <p class="text-center mt-8">
          <a
            class="bg-yellow-500 hover:bg-yellow-700 text-black text-2xl font-bold rounded focus:outline-none focus:shadow-outline inline-block mt-6 px-16 py-8"
            href="/static/TCC_Corporate Brochure_2019.pdf"
            target="_blank"
          >Download our brochure</a>
        </p>
      </div>
    </section>

    <section class="py-8 h-56 overflow-hidden">
      <img class="object-cover object-center" src="/img/hero-2.jpg" alt="A Crêpe" id="faq" />
    </section>

    <section class="p-24">
      <div class="container mx-auto">
        <h3>FAQ</h3>
        <h4>What is the difference between The Crêpe Café and Flying Pan?</h4>
        <p>
          They both stem from the same core concept but are illustrated differently
          from a design standpoint and differ in scope of menu and adaptability. The
          Crêpe Café brand is promoted in the Middle East and North Africa regions as
          well as in Australia. Flying Pan, the next evolution of The Crêpe Café brand,
          is expanding in the United States, the Caribbean, Brazil and South Korea.
        </p>
        <h4>What makes Flying Pan or The Crêpe Café different from their competitors?</h4>
        <p>
          With such a unique selling point, there is little to zero direct
          competition (depending on the region). We specialize in a delicacy that’s been
          enjoyed for centuries with a modern twist. Our concept, suitable all day,
          offers much more flexibility than a standard café or fast food outlets due to
          its diverse menu of delicacies and beverages whilst maintaining a compact and
          efficient operation since we do not require any specialist staff and do not
          have a full kitchen.
        </p>
        <h4>What is the cost of the crêpe?</h4>
        <p>
          The crêpe base point usually varies between USD4.00 and USD9.00 (some
          international markets may experience a higher base point range) and the
          average basket, including drinks, is USD10.00 to USD14.00. This makes it a
          reasonably priced dine in or take away option for families and singles.
        </p>
        <h4>What type of shops do you propose?</h4>
        <p>
          We offer a Full Café concept (usually in streets) and a Large Kiosk concept
          (usually in a shopping mall). In some circumstances, these two main types can
          be complemented by smaller kiosks or mobile units (catering) which are always
          linked to a bigger outlet. A van / truck based mobile concept is also in
          development.
        </p>
        <h4>How big is each shop?</h4>
        <p>
          The Full Café is anywhere between 1,000 to 2,500 sqf (90 to 230 sqm). The
          Large Kiosk requires around 400 sqf (37 sqm) for the shopfitting, plus
          seating area (35 seats minimum).
        </p>
        <h4>Who are your customers?</h4>
        <p>
          We welcome in our outlets a broad range of consumers, from singles to
          families, from students / professionals to retirees, and from locals to
          tourists. Women represent a significant portion of our guests. Overall, as a
          flexible meal eaten hot or cold, savory or sweet, everyone can enjoy a crêpe
          from the very young to the very wise!
        </p>
        <h4>What does the franchise system include?</h4>
        <p>
          It includes the use of the brand name, trademarks, recipes, operational
          systems and methods, and decor. Additionally, you will receive support in
          these specific areas :
        </p>
        <ul class="list-disc pl-8">
          <li>Site selection guidance</li>
          <li>Shop conceptual design including specifications for leasehold improvements</li>
          <li>Consolidated package for most of the equipment</li>
          <li>Initial training for owner, operations manager and staff</li>
          <li>
            Ongoing training and updates (including quality control) for increasing
            efficiency and profitability
          </li>
          <li>Ongoing culinary and beverage development</li>
          <li>
            Supply of main ingredients and favorable national contracts with suppliers
            of goods and services
          </li>
          <li>Operating manuals, procedures, marketing, and business management systems</li>
        </ul>
        <h4>Who designs and builds my store?</h4>
        <p>
          A. We will provide you with conceptual drawings and specifications for
          leasehold improvements (including some site specific drawings). You will
          need a local architect to do the technical / building plans & project
          management. You will also require a construction contractor (shopfitter)
          for the construction. We will have a restricted supervisory role to ensure
          consistency of quality standards.
        </p>
        <h4>Do you offer Area Development / Multi Outlet opportunities?</h4>
        <p>
          A. Yes. We are actively seeking qualified candidates to develop between
          3-10+ locations. We only offer single unit franchise opportunities in
          specific markets or under certain circumstances.
        </p>
        <h4>How much are the on-going royalties?</h4>
        <p>
          6% of total gross sales is the current standard in most of our existing
          regions.
        </p>
        <p></p>
        <h4>How much is the on-going marketing levy?</h4>
        <p>
          A. 2-3% of total gross sales is the current standard in most of our
          existing regions. This fund is managed at the regional level or locally
          depending on the situation.
        </p>
        <h4>What type of Marketing assistance is available?</h4>
        <p>
          A. Our Marketing Manual covers a wide range of marketing initiatives from
          grand opening programs to ongoing Local Store Marketing (LSM) as well as
          Public Relations and social media tips. As a Group, we have specific PR,
          social media and web tools to promote our brands worldwide.
        </p>
        <h4>How is the site selected for my outlet?</h4>
        <p>
          A. We will provide you with a checklist to assist you during this process.
          We will also share our opinion & advice for you to benefit from our
          experiences. However, you will require a local expert to further advise
          you.
        </p>
        <h4>How much can I expect to make in sales?</h4>
        <p>
          A. Legally, we cannot estimate the level of sales you will potentially
          make in your store but, from our experience, depending on many factors
          (such as type of shop, location, environment, demographics, country, etc),
          the current sales of our existing stores spread on many countries vary
          from USD400,000 to USD1.6 million+ on average.
        </p>
        <h4>Is there any experience necessary to own a BFC Retail Group franchise?</h4>
        <p>
          A. Our ideal target is someone who has the capacity and ability to manage
          expansion throughout its territory, demonstrating a depth in experience
          and strong management team. We also favor people already familiar with a
          franchise system and wishing to complement their existing portfolio with
          an exciting brand like ours. In some countries where single unit
          franchising is appropriate, we also welcome motivated and committed
          candidates with a strong background in business management or the food
          industry, franchise system conscious, strong customer focus and with a
          good local knowledge of their market. Overall, we are interested in anyone
          with a passion for food and driven for success and someone who has the
          financial capacity to turn its vision into a reality.
        </p>
        <h4>What will I need to invest to get my business started?</h4>
        <p>
          A. For Full Cafés and Large Kiosks, start up investment ranges from
          USD300,000 to USD550,000. These amounts may vary in some markets.
        </p>
        <h4>Q.What is the development timeframe?</h4>
        <p>
          A. Usually, from finalization of the legal process to the opening of the
          first outlet, it takes 6 to 8 months if the lease negotiation stays within
          the standard timeframe. The construction (renovation only) itself should
          take 3 months minimum from landlord design approval. These timeframes may
          significantly vary depending on the region and specific circumstances.
        </p>
        <h4>I'm interested in a BFC Retail Group franchise. What do I do?</h4>
        <p>
          A. Fill out the form on the right of your screen “Franchising with BFC”.
          The process may vary depending on which region / country you are
          interested in due to specific local legal regulations. However, the usual
          process is:
        </p>
        <ol class="list-decimal pl-8">
          <li>Fill out and send the “Interest Form” on our website;</li>
          <li>We will contact you by email to start the discussion;</li>
          <li>
            If we think there is a fit, we will send you the formal “Application
            Form” and “Confidentiality Agreement” for you to complete and return to
            us;
          </li>
          <li>
            We will organize phone conference or face to face meetings to further
            discuss a potential collaboration and its terms;
          </li>
          <li>
            We will send you the relevant legal document for you to peruse with your
            own advisors;
          </li>
          <li>
            If you decide to proceed, we will formalize our relationship by signing
            the said legal document; and
          </li>
          <li>Our journey together will commence!</li>
        </ol>
        <p>
          Note that in some countries (e.g. USA), specific “Disclosure
          Documents” and timeframes will apply in between these steps
        </p>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import MenuModal from "../components/MenuModal";

import images from "../img/*.jpg";

export default {
  props: [],
  components: {
    Header,
    Footer,
    Hero,
    MenuModal
  },
  data: () => ({ images })
};
</script>