<template>
  <div>
    <Header></Header>

    <Hero
      v-bind:images="[images['hero-1']]"
      v-bind:texts="[
        'A fast casual franchise focusing on crêpes, waffles and pancakes',
      ]"
    ></Hero>

    <section class="text-center p-24" id="about">
      <div class="container mx-auto">
        <h2>Our story</h2>
        <p class="pt-8 leading-relaxed">
          The Crêpe Café is BFC Retail Group’s first fast casual franchise.
          Launched in 2001 by French-Australian entrepreneurs, the pioneer
          restaurant was located in Surfers Paradise, Queensland, Australia
          where it was embraced as a fun, fresh and unique way of dining. It
          spread throughout the country, providing an upper fast food concept,
          with its unique and signature product, the crêpe. In 2007, The Crêpe
          Café expanded into the Middle East as an elegant fast casual café
          where it is still continuing to grow today. In 2019, The Crêpe Café
          expanded into India.
        </p>
      </div>
    </section>
    <section
      class="py-8 h-56 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/home-1.jpg')"
    ></section>

    <section
      class="text-center p-24 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/bg-1.jpg')"
    >
      <div class="container mx-auto">
        <h2>Worldly dining</h2>
        <p class="text-white pt-8 leading-relaxed">
          Spanning two continents, The Crêpe Café is tailored to suit our
          customers’ needs based on culture and geographic preferences. Focusing
          on more familiar flavors based on each shop’s location, our cafés have
          a strong following of people who enjoy everyday flavors with a twist.
          The eye catching color scheme of yellow and black is recognized
          internationally as a place where delicious meals and visual enjoyment
          come together for a unique dining experience. From Australia to India,
          The Crêpe Café provide enjoyable fresh dining experiences.
        </p>
      </div>
    </section>
    <section
      class="py-8 h-56 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/home-2.jpg')"
    ></section>

    <section class="text-center p-24 bg-tcc-yellow">
      <div class="container mx-auto">
        <h2 class="uppercase font-bold text-3xl text-white">Freshness first</h2>
        <p class="pt-8 text-black">
          Our cafés are designed with your enjoyment at its center. The unique
          cooking process of the crêpe, made in front of the customers, can be
          enjoyed as an entertaining 5 step process. Watch your crêpe as it is
          poured, flipped and filled with our fresh ingredients straight from
          the market, and enjoy the fun folding and savvy serving. Filled with a
          variety of sweet and savory ingredients from fresh vegetables, fruits
          to meat and dairy, we guarantee freshness from start to finish. The
          crêpe can be eaten hot or cold any time of the day as a dine-in,
          take-out or on the go option.
        </p>
      </div>
    </section>
    <section
      class="py-8 h-56 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/home-3.jpg')"
    ></section>

    <section
      class="p-24 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/img/bg-1.jpg')"
      id="contact"
    >
      <div class="container mx-auto md:flex">
        <div class="md:w-1/2 pr-6">
          <h2>Contact</h2>
          <p class="pt-8 text-white leading-relaxed">
            For General Inquiries and information on Franchising opportunities,
            please submit the form below and a representative will contact you
            shortly.
          </p>
          <p class="pt-8 text-white font-bold text-lg">Do not use this form to contact a shop in Australia</p>
        </div>
        <div class="mt-4 md:mt-0 md:w-1/2">
          <form name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
            <p class="hidden">
              <label>
                Don't fill this out if you're human:
                <input name="bot-field" />
              </label>
            </p>
            <div class="mb-4">
              <label
                class="block text-tcc-yellow text-sm font-bold mb-2"
                for="name"
                >Name</label
              >
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                required
              />
            </div>
            <div class="mb-4">
              <label
                class="block text-tcc-yellow text-sm font-bold mb-2"
                for="email"
                >Email</label
              >
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                required
              />
            </div>
            <div class="mb-6">
              <label
                class="block text-tcc-yellow text-sm font-bold mb-2"
                for="country"
                >Country</label
              >
              <input
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="country"
                name="country"
                type="text"
                placeholder="Country"
                required
              />
            </div>
            <div class="mb-6">
              <label
                class="block text-tcc-yellow text-sm font-bold mb-2"
                for="message"
                >Message</label
              >
              <textarea
                class="border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="message"
                name="message"
                rows="10"
                required
                placeholder="Your message here..."
              ></textarea>
            </div>
            <div class="flex items-center justify-between">
              <button
                class="bg-yellow-500 hover:bg-yellow-700 text-black font-bold py-2 px-16 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

import images from "../img/*.jpg";

export default {
  props: [],
  components: {
    Header,
    Footer,
    Hero,
  },
  data: () => ({ images }),
};
</script>
