<template>
  <main
    class="py-8 bg-center bg-no-repeat bg-cover hero-container flex justify-end items-center lg:p-24"
    v-bind:style="{ height: (height || 500) + 'px', backgroundImage: `url('${images[0]}' )` }"
  >
    <div class="uppercase text-right text-4xl md:text-6xl text-white mr-6 pl-16 max-w-6xl">
      <span class="bg-black">{{ texts[0] }}</span>
    </div>
  </main>
</template>

<script>
export default {
  props: ["images", "texts", "height"]
};
</script>

<style scoped>
main {
  mask-image: url("/img/hero-mask.svg");
  mask-size: cover;
  mask-position: bottom;
}
</style>