<template>
  <div>
    <Header></Header>

    <Hero v-bind:images="[images['hero-ps']]" v-bind:texts="[]" v-bind:height="300"></Hero>

    <section class="container mx-auto text-center my-12">
      <h2>Privacy statement</h2>
      <p>BFC Retail Group is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Statement of Privacy applies to the BFC Retail Group website and governs data collection and usage. By using the BFC Retail Group website, you consent to the data practices described in this statement.</p>

      <h3>Collection of your Personal Information</h3>
      <p>BFC Retail Group collects personally identifiable information, such as your e-mail address, name, home or work address or telephone number. BFC Retail Group also collects anonymous demographic information, which is not unique to you, such as your ZIP code, age, gender, preferences, interests and favorites.</p>
      <p>There is also information about your computer hardware and software that is automatically collected by BFC Retail Group. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used by BFC Retail Group for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the BFC Retail Group website.</p>
      <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through BFC Retail Group public message boards, this information may be collected and used by others. Note: BFC Retail Group does not read any of your private online communications.</p>
      <p>BFC Retail Group encourages you to review the privacy statements of websites you choose to link to from BFC Retail Group so that you can understand how those websites collect, use and share your information. BFC Retail Group is not responsible for the privacy statements or other content on websites outside of the BFC Retail Group and BFC Retail Group family of websites.</p>

      <h3>Use of your Personal Information</h3>
      <p>BFC Retail Group collects and uses your personal information to operate the BFC Retail Group website and deliver the services you have requested. BFC Retail Group also uses your personally identifiable information to inform you of other products or services available from BFC Retail Group and its affiliates. BFC Retail Group may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.</p>
      <p>BFC Retail Group does not sell, rent or lease its customer lists to third parties. BFC Retail Group may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is not transferred to the third party. In addition, BFC Retail Group may share data with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to BFC Retail Group, and they are required to maintain the confidentiality of your information.</p>
      <p>BFC Retail Group does not use or disclose sensitive personal information, such as race, religion, or political affiliations, without your explicit consent.</p>
      <p>BFC Retail Group keeps track of the websites and pages our customers visit within BFC Retail Group, in order to determine what BFC Retail Group services are the most popular. This data is used to deliver customized content and advertising within BFC Retail Group to customers whose behavior indicates that they are interested in a particular subject area.</p>
      <p>BFC Retail Group websites will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on BFC Retail Group or the site; (b) protect and defend the rights or property of BFC Retail Group; and, (c) act under exigent circumstances to protect the personal safety of users of BFC Retail Group, or the public.</p>

      <h3>Use of Cookies</h3>
      <p>The BFC Retail Group website use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
      <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the web server that you have returned to a specific page. For example, if you personalize BFC Retail Group pages, or register with BFC Retail Group site or services, a cookie helps BFC Retail Group to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same BFC Retail Group website, the information you previously provided can be retrieved, so you can easily use the BFC Retail Group features that you customized.</p>
      <p>You have the ability to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the BFC Retail Group services or websites you visit.</p>

      <h3>Security of your Personal Information</h3>
      <p>BFC Retail Group secures your personal information from unauthorized access, use or disclosure. BFC Retail Group secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure. When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Socket Layer (SSL) protocol.</p>

      <h3>Changes to this Statement</h3>
      <p>BFC Retail Group will occasionally update this Statement of Privacy to reflect company and customer feedback. BFC Retail Group encourages you to periodically review this Statement to be informed of how BFC Retail Group is protecting your information.</p>

      <h3>Contact Information</h3>
      <p>BFC Retail Group welcomes your comments regarding this Statement of Privacy. If you believe that BFC Retail Group has not adhered to this Statement, please contact BFC Retail Group at info@bfcretailgroup.com. We will use commercially reasonable efforts to promptly determine and remedy the problem.</p>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";

import images from "../img/*.jpg";

export default {
  props: [],
  components: {
    Header,
    Footer,
    Hero
  },
  data: () => ({ images })
};
</script>

<style scoped>
h2 {
  margin-bottom: 40px;
}
h3 {
  font-size: 1.3em;
  font-weight: bold;
  margin: 15px 0;
}
p {
  margin-bottom: 30px;
}
</style>