export const data = {
  crepes: {
    more: "More crêpes",
    content: [
      {
        image: "crepe1",
        title: "The Signature crêpe",
        text:
          "We offer a unique European dining experience with our signature dish. The crêpe is a very thin cooked pancake made from flour, egg and water. Whipped up in under 4 minutes on a hot plate, this dish can be fill with an array of fruits, vegetables, meat and dairy."
      },
      {
        image: "crepe3",
        title: "Sweet staff pick",
        text: ""
      },
      {
        image: "crepe4",
        title: "The Savoury Crêpe",
        text:
          "The savory crêpe is one of our main popular dishes. A quick process, the crêpe is cooked first, whilst still on the hot plate your favorite veggies and proteins are placed on top and allowed to heat ith the aromas sinking into the crêpe. Your meal is wrapped up and served hot straight from the hot plate."
      },
      {
        image: "crepe2",
        title: "The Sweet Crêpe",
        text:
          "We offer a unique European dining experience with our signature dish. The crêpe is a very thin cooked pancake made from flour, egg and water. Whipped up in under 4 minutes on a hot plate, this dish can be fill with an array of fruits, vegetables, meat and dairy."
      },
      {
        image: "crepe5",
        title: "Savoury staff pick",
        text: ""
      }
    ]
  },
  delights: {
    more: "More delights",
    content: [
      {
        image: "omelette",
        title: "Omelette",
        text:
          "A savory dish from a batter of beaten eggs that is cooked and filled with a combination of meat, cheese or vegetables. Enjoy it anytime of the day!"
      },
      {
        image: "pancakes",
        title: "Pancakes",
        text:
          "The traditional pancake is made from a flour batter and cooked on a hot plate. We serve it with whipped cream, ice cream or syrup. Try it with our selection of fresh fruits or the whole lot for a scrumptious breaky or midday delight."
      },
      {
        image: "salad",
        title: "Salads",
        text:
          "We have a fresh selection of salads for your choosing. A mix of greens, array of vegetables and dressings to accompany any meal, or on its own. Veffie lovers will enjoy a range of vegetables, with chicken turkey or egg available."
      },
      {
        image: "waffle",
        title: "Waffles",
        text:
          "A crispy grid-lined cake made from flour batter and cooked between two hot plates called a waffle iron. The traditional Belgium waffle is a filling treat served with whipped cream, ice cream, syrup or fruits."
      }
    ]
  },
  juices: {
    more: "More juices",
    content: [
      {
        image: "juice1",
        title: "Fresh juices",
        text:
          "Delicious and refreshing, our fresh juices are a healthy complement to the flavors of our food fare from omelettes to crêpes."
      },
      {
        image: "juice2",
        title: "Smoothies",
        text:
          "All made with real fruits including berries, bananas, and mangos, we offer fresh smoothes year round with seasonal selections available based on location."
      },
      {
        image: "juice3",
        title: "Milkshakes",
        text:
          "These milky indulgences are served with flavored ice cream, available in vanilla, chocolate or strawberry, they make a delicious treat any time of the day."
      }
    ]
  },
  coffee: {
    more: "More coffees",
    content: [
      {
        image: "cafe1",
        title: "Our blend",
        text:
          "Designed to complement the ingredients in our crêpes, pancakes and waffles, the blend was developed by coffee experts with an international track record for fine coffee making."
      },
      {
        image: "cafe2",
        title: "Hot coffee",
        text: ""
      },
      {
        image: "cafe3",
        title: "Iced drinks",
        text:
          "We have a delicious selection of cold drinks available in vanilla, chocolate or mocha. The Ice Frappes, made with ice and flavoured milk are refreshing and light. Add ice cream and whipped cream to indulge in a Crema Frappe."
      }
    ]
  }
};
